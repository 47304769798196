import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { isSignUpRequired } from '../utils/extract-requirements';
import { PATH_SIGN_UP } from '../common/constants/paths';

export default defineNuxtRouteMiddleware(() => {
  const { data } = useAuth();
  const $auth = data.value ?? null;

  if (!$auth) {
    return;
  }

  const isSignUpRedirectRequired: boolean = isSignUpRequired($auth.requirements);

  if (isSignUpRedirectRequired || !$auth.approved) {
    return navigateTo({ path: PATH_SIGN_UP });
  }
});
